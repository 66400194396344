import React from "react";
import { object, any } from "prop-types";

export const HeadingSection = ({ style, children }) => (
  <h1
    style={{
      color: "rgb(20, 51, 91)",
      marginBottom: "32px",
      fontSize: "1.57em",
      ...style,
    }}
  >
    {children}
  </h1>
);

HeadingSection.propTypes = { style: object, children: any };
