import React from "react";
import { Row, Col, Card, Typography } from "antd";
import { HeadingSection } from "../headings";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import { InternalLink, InternalLinkTypes } from "../links";

const { Title, Text } = Typography;

export const Testimonials = ({ showFindMoreLink = true }) => {
  const data = useStaticQuery(graphql`
    query {
      ImgChris: file(relativePath: { eq: "other/chris.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <div>
      <HeadingSection>Testimonials</HeadingSection>
      <Card style={{ maxWidth: "800px", margin: "auto" }}>
        <Row gutter={16}>
          <Col md={16} xs={24}>
            <Text style={{ fontSize: "1.1rem" }}>
              "I’m so pleased that we brought Brett and the pdView team on board
              to deliver on the vision for Loy Yang B auto-bidding. As energy
              markets transition, it is important for our traders and
              generation fleet to adapt to the changing needs of the market. I’m
              very proud of this achievement and we look forward to pursuing
              further opportunities together."
            </Text>
            <br />
            <br />
            <Text type={"secondary"}>
              Alinta Energy went live with pdBidr Auto Bidding in Sept 2021.
              <br />
              {showFindMoreLink && (
                <InternalLink url={InternalLinkTypes.ServicesVadvisor}>
                  Find out more about pdBidr
                </InternalLink>
              )}
            </Text>
          </Col>
          <Col md={8} xs={24}>
            <Img
              fluid={data.ImgChris.childImageSharp.fluid}
              style={{
                borderRadius: "50%",
                maxWidth: "150px",
                margin: "auto",
                marginBottom: "8px",
              }}
            />
            <Title level={4}>Chris McDonagh</Title>
            <Text>General Manager, Alinta Energy</Text>
          </Col>
        </Row>
      </Card>
    </div>
  );
};
